import { Vue, Component } from 'vue-property-decorator'

import { PlayerStore, IQueuePushParams } from '~/store/player'

@Component
export class InfiniteLoadingSetState extends Vue {
    @PlayerStore.Mutation('ADD_BEATS_TO_PLAYLIST') ADD_BEATS_TO_PLAYLIST: (params: IQueuePushParams) => void

    setLoadingStateAndUpdatePlayerQueue($state: any, fetchedBeats: IBeat[]) {
        if ($state) {
            if (fetchedBeats.length > 0) $state.loaded()
            else $state.complete()
        }
        // TODO: this pushses on the original beat array as well for whatever fucking reason
        // @ts-ignore
        // this.ADD_BEATS_TO_PLAYLIST({ route: this.$route.path, beatsToAdd: fetchedBeats })
    }
}
