





























import { Vue, Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import PBeatTable from '@elitepage/components/PBeatTable.vue'

import { ElitepageStore } from '@elitepage/store/elitepage'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { fetchBeats } from '@elitepage/api/beats'
import { fetchAllGenresLight } from '~/api/genre'
import { fetchAllArtistTypesLight } from '~/api/artist-type'

@Component({ components: { PBeatTable } })
export default class Beats extends Mixins(InfiniteLoadingSetState) {
    @Prop({ type: String }) producerSlug!: IProducer['slug']

    beats: IBeat[] = []
    genres: IGenreLight[] = []
    artistTypes: IArtistTypeLight[] = []
    isArtistTypeDropdownOpen = false
    isGenreDropdownOpen = false

    @ElitepageStore.State('producer') producer: IProducer

    get selectedGenre() {
        if (this.$route.query.genre && this.genres) return this.genres.find(genre => genre.slug === this.$route.query.genre)
    }

    get selectedArtistType() {
        if (this.$route.query.artistType && this.artistTypes) return this.artistTypes.find(artistType => artistType.slug === this.$route.query.artistType)
    }

    @ElitepageStore.Action('fetchSingleProducer') fetchSingleProducer: (producerSlug: IProducer['slug']) => void

    closeArtistDropdown() {
        this.isArtistTypeDropdownOpen = false
    }

    closeGenreDropdown() {
        this.isGenreDropdownOpen = false
    }

    setArtistType(artistType: IArtistType) {
        this.closeArtistDropdown()
        Vue.router.push({ name: 'Beats', query: { artistType: artistType.slug } })
    }

    setGenre(genre: IGenre) {
        this.closeGenreDropdown()
        Vue.router.push({ name: 'Beats', query: { genre: genre.slug } })
    }

    async updateBeatsTable($state: any, offset: number) {
        const beats = await fetchBeats(offset, 14, this.producerSlug, this.$route.query.genre as string, this.$route.query.artistType as string)
        this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
        // set loading state for vue-infinite-loading
        this.setLoadingStateAndUpdatePlayerQueue($state, beats)
    }

    @Watch('$route', { immediate: true })
    route() {
        // on route change reload the beats with new filters
        this.beats = []
        this.updateBeatsTable(null, 0)
    }

    async mounted() {
        this.artistTypes = await fetchAllArtistTypesLight()
        this.genres = await fetchAllGenresLight()
    }
}
