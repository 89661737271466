import { get } from '~/services/http-requests'
import { _BS_BEATS, BeatFilterType } from '~/api/beats'

export async function fetchBeats(offset = 0, limit = 14, producerSlug: string, genreSlug?: string, artistTypeSlug?: string): Promise<IBeat[]> {
    let url = `limit=${limit}&offset=${offset}&producer__slug=${producerSlug}`
    if (genreSlug) {
        url += `&genre__slug=${genreSlug}`
    }
    if (artistTypeSlug) {
        url += `&artist_type__slug=${artistTypeSlug}`
    }
    const data = await get({ baseProperty: _BS_BEATS, fullUrl: `${_BS_BEATS}.json?${url}` })
    return data.results ? data.results : data
}
