import { get } from '~/services/http-requests'

const _BS_ARTIST_TYPE = 'artist_types'

/*
 **** GET ****
 */

function fetchAllArtistTypesLight(): Promise<IArtistTypeLight[]> {
    return get({ fullUrl: `api/${_BS_ARTIST_TYPE}.json?fields=name,id,slug`, isFreeRequest: true })
}

function fetchAllArtistTypesLightGte(): Promise<IArtistTypeLight[]> {
    const minNumberOfBeats = 15
    return get({
        baseProperty: _BS_ARTIST_TYPE,
        fullUrl: `${_BS_ARTIST_TYPE}.json?number_of_beats__gte=${minNumberOfBeats}&fields=name,slug,id`
    })
}

export { fetchAllArtistTypesLight, fetchAllArtistTypesLightGte }
