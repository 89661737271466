import { get } from '~/services/http-requests'

const _BS_GENRES = 'genres'

/*
 **** GET ****
 */
function fetchAllGenresLight(): Promise<IGenreLight[]> {
    return get({ fullUrl: `api/${_BS_GENRES}.json?fields=name,id,slug`, isFreeRequest: true })
}

export { fetchAllGenresLight }
